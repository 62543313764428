import { useState, useContext, createContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import useCategoryHook from 'context/OrganizationContext/categoryHook';
import useCouponHook from 'context/OrganizationContext/couponHook';
import useEventHook from 'context/OrganizationContext/eventHook';
import useMediaHook from 'context/OrganizationContext/mediaHook';
import useGeoHook from 'context/OrganizationContext/geoHook';
import { useAlertContext, AlertNotice } from 'context/AlertLayoutContext';
import { FETCH_ORGANIZATION, LIST_ORGANIZATION_MEDIA, LIST_IMPRESSIONS } from 'data/queries'
import { CREATE_ORGANIZATION, PERSIST_ORGANIZATION, RESET_ACCOUNT_CREDENTIALS } from 'data/mutations';
import {
  IDataPagination,
  IImpression,
  IImpressionSummary,
  IMedia,
  IOrganization,
  IContactInput,
  IOrganizationMedia,
  ISessionScope,
} from 'data/types';

interface IOrganizationContext {
  sessionContext: ISessionScope | undefined
  setSessionContext: (contextScope: any) => void
  organization: IOrganization | undefined
  impressions: IImpressionSummary | undefined
  media: IOrganizationMedia | undefined
  isLoadingOrganization: boolean
  fetchImpressionContext: any
  isLoadingImpressions: boolean
  mediaContext: any
  isLoadingMedia: boolean
  organizationSaveContext: any,
  organizationCreateContext: any,
  credentialsResetContext: any,
  loadOrganization: () => void,
  saveOrganization: (param: IOrganization, contact?: IContactInput) => void,
  requestImpressions: (organizationUuid: string, fromTimestamp: string, toTimestamp: string) => void
  requestMedia: (organizationUuid: string, page: number, pageSize: number) => void
  saveMedia: (organizationUuid: string, mediaInstance: IMedia) => void
  deleteMedia: (mediaUuid: string) => void
  resetCredentials: (username: string, newPassword: string) => void
  hookForCategory: any
  hookForCoupon: any
  hookForEvent: any
  hookForMedia: any
  hookForGeo: any
  blankOrganization: IOrganization
}

const OrganizationContext = createContext<IOrganizationContext | undefined>(undefined);

const OrganizationProvider = (props: any) => {
  const { children } = props;
  const navigate = useNavigate();
  const hookForCategory = useCategoryHook();
  const hookForCoupon = useCouponHook();
  const hookForEvent = useEventHook();
  const hookForMedia = useMediaHook();
  const hookForGeo = useGeoHook();

  let contextScope: any | undefined;
  const data: string | null = sessionStorage.getItem("context");
  if (data) {
    const scope: any = window.atob(data);
    if (scope) {
      const scopeDetail: any = JSON.parse(scope);
      if (scopeDetail) {
        contextScope = scopeDetail.data;  
      }
    }
  }
  
  const defaultAcceptedCreditCards = [
    {"name":"American Express","accepted":false},
    {"name":"Dinners Card","accepted":false},
    {"name":"Discover","accepted":false},
    {"name":"JP","accepted":false},
    {"name":"Master Card","accepted":false},
    {"name":"Visa","accepted":false}
  ];

  const defaultHoursOfOperation = [
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Sunday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Monday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Tuesday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Wednesday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Thursday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Friday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Saturday"}
  ];

  const blankOrganization: IOrganization = {
    legacyOwnerId: 0,
    organizationUuid: "",
    name: "",
    provinceOrState: 'California',
    country: 'USA',
    keywords: [],
    acceptedCreditCards: defaultAcceptedCreditCards,
    hoursOfOperation: defaultHoursOfOperation,
    hasDelivery: false,
    hasParking: false,
  }

  const [sessionContext, setSessionContext] = useState<ISessionScope>(contextScope);
  const { pushNotice, pushMessageWithContext, AlertContextType } = useAlertContext();
  const { archiveMedia, updateMediaCaption } = useMediaHook();
  const [hasRequestedFullContext, setHasRequestedFullContext] = useState(false)
  const [organization, setOrganization] = useState<IOrganization>(blankOrganization);
  const [media, setMedia] = useState<IOrganizationMedia>();
  const [impressions, setImpressions] = useState<IImpressionSummary>()
  const [fetchOrganization, fetchOrganizationContext] = useLazyQuery(FETCH_ORGANIZATION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    onCompleted: data => {
      const odata: IOrganization = data.organizationFromSession;
      setOrganization(odata)
    },
    onError: error => {
      console.log('fetchOrganization::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization: {error.message}
        </AlertNotice>
      )
    }
  })

  const [persistOrganization, persistOrganizationContext] = useMutation(PERSIST_ORGANIZATION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [FETCH_ORGANIZATION],
    onCompleted: data => {
      // const odata: IOrganization = data.updateOrganization;
      pushMessageWithContext(AlertContextType?.Success, 'Organization saved successfully')
      navigate('/')
    },
    onError: error => {
      console.log('persistOrganization::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization (Save): {error.message}
        </AlertNotice>
      )
    }
  })

  const [createOrganization, createOrganizationContext] = useMutation(CREATE_ORGANIZATION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      // const odata: IOrganization = data.createOrganization;
      window.location.href = 'https://www.gonelocal.com/thankyou';
    },
    onError: error => {
      console.log('createOrganization::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization (Creation): {error.message}
        </AlertNotice>
      )
    }
  })

  const [changeCredentials, changeCredentialsContext] = useMutation(RESET_ACCOUNT_CREDENTIALS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const odata: any = data.changeCredentials;
      console.log('Were Credentials Updated: ', odata.success)
    },
    onError: error => {
      console.log('changeCredentials::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Change Account Credentials: {error.message}
        </AlertNotice>
      )
    }
  })

  const [fetchImpressions, fetchImpressionContext] = useLazyQuery(LIST_IMPRESSIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const impressionData: IDataPagination = data.impressions;
      const counts: IImpression[] = impressionData?.items
      setImpressions(counts[0]?.tally);
      // let total = 0;
      // let _counts: any = counts?.tally;
      // _counts.forEach((element: any) => {
      //   total += element.impressions;
      // });
      // console.log('total impressions:', total);
    },
    onError: error => {
      console.log('fetchImpressions::Error: ', error);
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization Impressions: {error.message}
        </AlertNotice>
      )
    }
  });
  const [listMedia, mediaContext] = useLazyQuery(LIST_ORGANIZATION_MEDIA, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const mdata: IDataPagination = data.media;
      setMedia(mdata.items)
    },
    onError: error => {
      console.log('listMedia::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization Media: {error.message}
        </AlertNotice>
      )
    }
  })

  useEffect(() => {
    if (!hasRequestedFullContext) {
      setHasRequestedFullContext(true);
      fetchOrganization()
    }
  }, [
    hasRequestedFullContext,
    setHasRequestedFullContext,
    fetchOrganization,
  ])

  const loadOrganization = () => {
    fetchOrganization()
  }

  const saveOrganization = (param: IOrganization, contact?: IContactInput, geoCityUuid?: string) => {
    if (contact && geoCityUuid) {
      createOrganization({
        variables: {
          param,
          geoCityUuid,
          contact,
        }
      })
    } else if (param.organizationUuid !== undefined && param.organizationUuid !== "") {
      persistOrganization({
        variables: {
          param
        }
      })
    } else {
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Unable to save organization without an organizationUuid!
        </AlertNotice>
      )
    }
  }

  const resetCredentials = (username: string, newPassword: string) => {
    changeCredentials({
      variables: {
        identityUuid: organization?.primaryContactUuid,
        newUsername: username,
        newPassword,
      }
    })
  }

  const requestImpressions = (organizationUuid: string, fromTimestamp: string, toTimestamp: string) => {
    fetchImpressions({
      variables: {
        organizationUuid,
        fromTimestamp,
        toTimestamp,
      }
    })  
  }

  const requestMedia = (organizationUuid: string, page: number, pageSize: number) => {
    listMedia({
      variables: {
        organizationUuid,
        page,
        pageSize,
      }
    })
  }

  const deleteMedia = (mediaUuid: string) => {
    archiveMedia({
      variables: {
        organizationMediaUuid: mediaUuid,
      }
    })
  }

  const saveMedia = (organizationMediaUuid: string, mediaInstance: IMedia) => {
    updateMediaCaption({
      variables: {
        organizationMediaUuid: organizationMediaUuid,
        mediaUuid: mediaInstance.mediaUuid,
        caption: mediaInstance.caption,
      }
    })
  }

  return (
    <OrganizationContext.Provider value={{
      sessionContext, setSessionContext,
      organization,
      impressions,
      media,
      isLoadingOrganization: fetchOrganizationContext.loading,
      fetchImpressionContext,
      isLoadingImpressions: fetchImpressionContext.loading,
      mediaContext,
      isLoadingMedia: mediaContext.loading,
      organizationSaveContext: persistOrganizationContext,
      organizationCreateContext: createOrganizationContext,
      credentialsResetContext: changeCredentialsContext,
      loadOrganization,
      saveOrganization,
      requestImpressions,
      requestMedia,
      saveMedia,
      deleteMedia,
      resetCredentials,
      hookForCategory,
      hookForCoupon,
      hookForEvent,
      hookForMedia,
      hookForGeo,
      blankOrganization,
    }}>
        {children}
    </OrganizationContext.Provider>
  )
}

const useOrganization = (): any => {
  const context = useContext(OrganizationContext)
  if (context === undefined) {
    throw new Error('useOrganization must be used within OrganizationProvider');
  }
  return context;
}

const useCategory = (): any => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('useCategory must be used within OrganizationProvider');
  }
  return context.hookForCategory;
}

const useCoupons = (): any => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('useMedia must be used within OrganizationProvider');
  }
  return context.hookForCoupon;
}

const useEvents = (): any => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('useMedia must be used within OrganizationProvider');
  }
  return context.hookForEvent;
}

const useMedia = (): any => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('useMedia must be used within OrganizationProvider');
  }
  return context.hookForMedia;
}

const useGeo = (): any => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('useGeo must be used within OrganizationProvider');
  }
  return context.hookForGeo;
}

export { 
  OrganizationContext, 
  OrganizationProvider,
  useOrganization,
  useCategory,
  useCoupons,
  useEvents,
  useMedia,
  useGeo,
}