import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ServiceUrl } from 'data/domains';

const client = new ApolloClient({
  link: createHttpLink({
    uri: `${ServiceUrl()}/graphql`
  }),
  cache: new InMemoryCache(),
})

export function updateGraphqlHeaders() {
  const context: any = sessionStorage.getItem("context");
  if (context) {
    const authLink = setContext((_, { headers }) => {
      return {
          headers: {
            ...headers,
            'Authorization': (context
              ? `JWT ${context}`
              : '')
          }
      }
    })
    
    client.setLink(
      authLink.concat(
        createHttpLink({
          uri: `${ServiceUrl()}/graphql` 
        })
      )
    );
  }
}
updateGraphqlHeaders() // Init

export default client;