import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthorizeUrl } from 'data/domains';
import { useOrganization } from 'context/OrganizationContext';
import ThreeDotLoader from 'views/components/loaders';

import 'assets/css/pagelayout.scss';

const Events = lazy(() => import('views/events'));
const ComposeEvent = lazy(() => import('views/composers/event'));
const ComposeIncentive = lazy(() => import('views/composers/incentive'));
const Gallery = lazy(() => import('views/gallery'));
const Home = lazy(() => import('views/index'));
const Incentives = lazy(() => import('views/incentives'));
const Profile = lazy(() => import('views/organization'));
const Register = lazy(() => import('views/register'));
const LoginContext = lazy(() => import('context/ContextFromLoginCallback'));
const LogoffContext = lazy(() => import('context/ContextClearLogoutCallback'));

const App = () => {
  const { sessionContext } = useOrganization();

  if (window.location.pathname.indexOf('/.scope') !== -1) {
    return (
      <Suspense fallback={<ThreeDotLoader show={true} />}>
        <Routes>
          <Route path="/.scope/:scope" element={<LoginContext/>}/>
        </Routes>
      </Suspense>
    );
  } else if (window.location.pathname.indexOf('/registration') !== -1) {
    return (
      <Suspense fallback={<ThreeDotLoader show={true} />}>
        <Routes>
          <Route path="/registration" element={<Register/>}/>
        </Routes>
      </Suspense>
    );
  } else if (!sessionContext?.identityUuid) {
    if (window.name === '__merchant_window__') {
      window.close()
    } else {
      window.location.href = AuthorizeUrl();
    }
    return (<></>);
  }

  return (
    <div>
      <Suspense fallback={<ThreeDotLoader show={true} />}>
        <Routes>
          <Route path="/coupons/:organizationIncentiveUuid/edit" element={<ComposeIncentive />}/>
          <Route path="/coupons/create" element={<ComposeIncentive />}/>
          <Route path="/coupons" element={<Incentives />}/>
          <Route path="/events/:organizationEventUuid/edit" element={<ComposeEvent />}/>
          <Route path="/events/create" element={<ComposeEvent />}/>
          <Route path="/events" element={<Events />}/>
          <Route path="/gallery" element={<Gallery />}/>
          <Route path="/profile" element={<Profile />}/>
          <Route path="/logout" element={<LogoffContext />}/>
          <Route path="/" element={<Home />}/>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
