import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { useAlertContext } from 'context/AlertLayoutContext';
import { LIST_INCENTIVES } from 'data/queries';
import { PERSIST_INCENTIVE, ARCHIVE_INCENTIVE } from 'data/mutations';
import {
  IDataPagination,
  IOrganizationIncentive,
  IPagination,
} from 'data/types';

export default function useCouponHook() {
  const blankIncentive: IOrganizationIncentive = {
    organizationIncentiveUuid: '',
    organizationUuid: '',
    mediaUuid: undefined,
    name: '',
    description: undefined,
    publishAt: DateTime.now().toISO(),
    validAt: DateTime.now().plus({days: 1}).toISO(),
    expireAt: DateTime.now().plus({days: 2}).toISO(),
    viewOrder: -1,
  }

  const { pushErrorMessage, pushMessageWithContext, AlertContextType } = useAlertContext();
  const [incentives, setIncentives] = useState<IOrganizationIncentive[]>();
  const [selectedIncentive, setSelectedIncentive] = useState<IOrganizationIncentive>(blankIncentive)
  const [incentivePagination, setIncentivePagination] = useState<IPagination>();

  const [listIncentives, listIncentiveContext] = useLazyQuery(LIST_INCENTIVES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const incentiveData: IDataPagination = data.incentives;
      setIncentives(incentiveData?.items);
      setIncentivePagination(incentiveData?.pagination);
    },
    onError: error => {
      console.log('listIncentives::Error: ', error);
      pushErrorMessage('Unable to list available coupons - ' + error.message)
    }
  })
  const [persistIncentive, persistIncentiveContext] = useMutation(PERSIST_INCENTIVE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_INCENTIVES],
    onCompleted: data => {
      const incentiveData: IOrganizationIncentive = data.persistIncentive;
      setSelectedIncentive(incentiveData);
      pushMessageWithContext(AlertContextType.Success, 'Coupon saved successfully')
    },
    onError: error => {
      console.log('persistIncentive::Error: ', error);
      pushErrorMessage('Unable to save coupon - ' + error.message)
    }
  })
  const [archiveIncentive, archiveIncentiveContext] = useMutation(ARCHIVE_INCENTIVE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_INCENTIVES],
    onCompleted: data => {
      const incentiveData: IOrganizationIncentive = data.persistIncentive;
      setSelectedIncentive(blankIncentive);
      pushMessageWithContext(AlertContextType.Success, 'Coupon archived successfully')
    },
    onError: error => {
      console.log('archiveIncentive::Error: ', error);
      pushErrorMessage('Unable to archive coupon - ' + error.message)
    }
  })

  const list = (organizationUuid: string, page: number, pageSize: number) => {
    listIncentives({
      variables: {
        organizationUuid,
        page,
        pageSize,
      }
    })
  }

  const saveIncentive = (param: IOrganizationIncentive) => {
    persistIncentive({
      variables: {
        param,
      }
    })
  }

  const deleteIncentive = (uuid: string) => {
    archiveIncentive({
      variables: {
        organizationIncentiveUuid: uuid,
      }
    })
  }

  return {
    blankIncentive,
    incentives,
    incentivePagination,
    selectedIncentive,
    setSelectedIncentive,
    list,
    incentiveListContext: listIncentiveContext,
    isLoadingIncentive: listIncentiveContext.loading,
    saveIncentive,
    incentiveSaveContext: persistIncentiveContext,
    isSavingIncentive: persistIncentiveContext.loading,
    deleteIncentive,
    incentiveArchiveContext: archiveIncentiveContext,
    isArchivingIncentive: archiveIncentiveContext.loading,
  }
}