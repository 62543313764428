import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAlertContext } from 'context/AlertLayoutContext';
import { LIST_ORGANIZATION_MEDIA, FETCH_ORGANIZATION } from 'data/queries'
import {
  MEDIA_REQUEST_UPLOAD,
  MEDIA_CONFIRM_UPLOAD,
  MEDIA_CAPTION_UPDATE,
  MEDIA_ARCHIVE
} from 'data/mutations';
import {
  IMedia,
  IMediaUploadRequest,
  IOctetMetadata
} from 'data/types';


export default function useMediaHook() {

  const { pushErrorMessage, pushMessageWithContext, AlertContextType } = useAlertContext();
  let sourceFile: File;
  let octetMetadata: IOctetMetadata;

  const [confirmUpload, confirmUploadContext] = useMutation(MEDIA_CONFIRM_UPLOAD, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_ORGANIZATION_MEDIA, FETCH_ORGANIZATION],
    onCompleted: data => {
      const mediaData: IMedia = data.confirmMediaUpload;
    },
    onError: error => {
      console.log('confirmUpload::Error: ', error);
      pushErrorMessage('Unable to confirm media upload - ' + error.message)
    }
  })

  const [requestUpload, requestUploadContext] = useMutation(MEDIA_REQUEST_UPLOAD, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: async data => {
      const uploadData: IMediaUploadRequest = data.requestMediaUpload;
      if (sourceFile && octetMetadata) {
        try {
          const res = await window.fetch(uploadData.uploadUrl, {
            method: 'PUT',
            body: sourceFile,// formData,
            headers: {
              'x-amz-acl': 'public-read',
              'Content-Type': sourceFile.type,
            }
          })

          if (res.status === 200) {
            confirmUpload({
              variables: {
                organizationUuid: uploadData.organizationUuid,
                metadata: octetMetadata,
              }
            })
          }
        } catch(exp) {
          console.log('requestUpload::Error:', exp);
          pushErrorMessage('Unable to upload media - ' + exp)
        }

      } else {
        pushErrorMessage('Select the source file before attempting upload');
      }
    },
    onError: error => {
      console.log('requestUpload::Error: ', error);
      pushErrorMessage('Unable to request media upload - ' + error.message)
    }
  })

  const uploadMedia = (organizationUuid: string, segment: string, files: any) => {
    let mediafiles = [];
    if (files instanceof FileList) {
      for(let item of files) {
        mediafiles.push(item);
      }
    } else {
      mediafiles = files;
    }

    mediafiles.forEach((file: any) => {
      const metadata: IOctetMetadata = {
        lastModified: ""+file.lastModified,
        contentLength: file.size,
        mimeType: file.type,
        filename: file.name,
        name: file.name,
        segment: segment ? segment : 'gallery',
        viewOrder: -1,
        uuid: organizationUuid.replaceAll('-',''),
      };

      octetMetadata = metadata;
      sourceFile = file;

      requestUpload({
        variables: {
          organizationUuid: organizationUuid.replaceAll('-',''),
          filename: metadata.filename,
          segment: metadata.segment,
          mimeType: metadata.mimeType,
          contentLength: metadata.contentLength,
        }
      });
    })
  }

  const [archiveMedia, archiveMediaContext] = useMutation(MEDIA_ARCHIVE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_ORGANIZATION_MEDIA],
    onCompleted: data => {
      const archiveData: any = data.archiveMedia;
    },
    onError: error => {
      console.log('archiveMedia::Error: ', error);
      pushErrorMessage('Unable to archive media - ' + error.message)
    }
  })

  const [updateMediaCaption, updateMediaCaptionContext] = useMutation(MEDIA_CAPTION_UPDATE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_ORGANIZATION_MEDIA],
    onCompleted: data => {
      const updateMediaCaption: any = data.updateMediaCaption;
    },
    onError: error => {
      console.log('updateMediaCaption::Error: ', error);
      pushErrorMessage('Unable to archive media - ' + error.message)
    }
  })

  return {
    uploadMedia,
    archiveMedia,
    updateMediaCaption,
  }
}
