import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAlertContext } from 'context/AlertLayoutContext';
import { LIST_GEO_STATES, LIST_GEO_CITIES_BY_STATE } from 'data/queries';
import {
  IDataPagination,
  IGeoState,
  IGeoCity,
} from 'data/types';

export default function useGeoHook() {
  const { pushErrorMessage } = useAlertContext();
  const [geoStates, setGeoStates] = useState<IGeoState[]>();
  const [geoCities, setGeoCities] = useState<IGeoCity[]>();
  const [listGeoStates, listContext] = useLazyQuery(LIST_GEO_STATES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const geoStateData: IDataPagination = data.geoStates;
      setGeoStates(geoStateData?.items);
    },
    onError: error => {
      console.log('listGeoStates::Error: ', error);
      pushErrorMessage('Unable to list geography information - ' + error.message)
    }
  })

  const [citiesByState, cityContext] = useLazyQuery(LIST_GEO_CITIES_BY_STATE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const geoCityData: IDataPagination = data.geoCitiesByState;
      setGeoCities(geoCityData?.items);
    },
    onError: error => {
      console.log('citiesByState::Error: ', error);
      pushErrorMessage('Unable to list cities for the selected state - ' + error.message)
    }

  })

  const listStates = () => {
    listGeoStates()
  }

  const listCities = () => {
    citiesByState()
  }

  return {
    geoStates,
    geoCities,
    listStates,
    listCities,
    isFetching: listContext.loading,
    isFetchingCities: cityContext.loading,
  }
}