import { Link, useLocation } from 'react-router-dom';
import 'assets/css/navbar-menu.scss';
import logo from 'assets/images/gonelocal_logo_white.png';
import { PageLayoutType } from '.';

interface IPageLayoutHeaderProps {
  layout?: string
}

const PageLayoutHeader = (props: IPageLayoutHeaderProps) => {
  const location = useLocation();

  return (
    <nav key='base-navigation-key' className={`navbar sticky-top navbar-expand-lg navbar-dark ${props?.layout || 'bg-primary'}`}>
      <div className='container-fluid'>
        {props?.layout === PageLayoutType.Unauthenticated ? (
          <a className='navbar-brand' href='https://www.gonelocal.com'><img src={logo} className='navbar-logo' alt='GoneLocal'/></a>
          ):(
          <Link className='navbar-brand' to='/'><img src={logo} className='navbar-logo' alt='GoneLocal'/></Link>
        )}

        <button className='navbar-toggler' type='button' 
          data-bs-toggle='collapse' 
          data-bs-target='#navbarSupportedContent' 
          aria-controls='navbarSupportedContent' 
          aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          {props?.layout !== PageLayoutType.Unauthenticated &&
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
          <li className='nav-item'>
            <Link className={`nav-link${location.pathname === '/profile' ? ' active' : ''}`} area-current='page' to='/profile'>Company Profile</Link>
          </li>
          <li className='nav-item'>
            <Link className={`nav-link${location.pathname === '/gallery' ? ' active' : ''}`} area-current='page' to='/gallery'>Gallery</Link>
          </li>
          <li className='nav-item'>
            <Link className={`nav-link${location.pathname === '/events' ? ' active' : ''}`} area-current='page' to='/events'>Events</Link>
          </li>
          <li className='nav-item'>
            <Link className={`nav-link${location.pathname === '/coupons' ? ' active' : ''}`} area-current='page' to='/coupons'>Coupons/Specials</Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link' area-current='page' to='/logout'>Logout</Link>
          </li>
        </ul>
        }
        </div>
      </div>
    </nav>
  )
}

export default PageLayoutHeader;