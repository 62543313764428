import { gql } from '@apollo/client';

const LIST_IMPRESSIONS = gql`
query impressions(
  $organizationUuid: ID!,
  $fromTimestamp: String,
  $toTimestamp: String
) {
  impressions(
    organizationUuid: $organizationUuid,
    fromTimestamp: $fromTimestamp,
    toTimestamp: $toTimestamp
  ) {
    items {
      ... on ImpressionSummary {
        averagePerDay
        tally {
          impressions
          date
          hour
        }
      }
    }
    pagination {
      page
      pageSize
      pageCount
      rowCount
      maxRows
    }
  }
}
`

const FETCH_ORGANIZATION = gql`
query organizationFromSession {
  organizationFromSession {
    organizationUuid
    primaryContactUuid
    primaryContact {
      credentials {
        credential
      }
    }
    primaryCategoryUuid
    primaryCategory {
      name
    }
    secondaryCategoryUuid
    secondaryCategory {
      name
    }
    mediaUuid
    logo {
      mediaUuid
      serverFilename
      serverUri
      originalFilename
      mimeType
      slug
      caption
      attributes
    }
    legacyOwnerId
    name
    slug
    description
    phonePrimary
    phoneSecondary
    fax
    email
    website
    street
    address
    city
    provinceOrState
    postalCode
    country
    keywords
    acceptedCreditCards {
      name
      accepted
    }
    hoursOfOperation {
      dayOfWeek
      hours {
        isOpen
        openAt
        closeAt
      }
    }
    hasDelivery
    hasParking
  }
}
`

const LIST_ORGANIZATION_MEDIA = gql`
query media(
  $organizationUuid: ID!,
  $page: Int!,
  $pageSize: Int!
) {
  media(
    organizationUuid: $organizationUuid,
    page: $page,
    pageSize: $pageSize
  ) {
    items {
      ... on OrganizationMedia {
        organizationMediaUuid
        viewOrder
        media {
          mediaUuid
          serverFilename
          serverUri
          originalFilename
          mimeType
          slug
          caption
          attributes
        }
      }
    }
    pagination {
      page
      pageSize
      pageCount
      rowCount
      maxRows
    }
  }
}
`

const LIST_INCENTIVES = gql`
query incentives(
  $organizationUuid: ID!,
  $page: Int!,
  $pageSize: Int!
) {
  incentives(
    organizationUuid: $organizationUuid,
    page: $page,
    pageSize: $pageSize
  ) {
    items {
      ... on OrganizationIncentive {
        organizationIncentiveUuid
        organizationUuid
        mediaUuid
        name
        description
        publishAt
        validAt
        expireAt
        viewOrder
        updatedAt
      }
    }
    pagination {
      page
      pageSize
      pageCount
      rowCount
      maxRows
    }
  }
}
`

const LIST_EVENTS = gql`
query events(
  $organizationUuid: ID!,
  $page: Int!,
  $pageSize: Int!
) {
  events(
    organizationUuid: $organizationUuid,
    page: $page,
    pageSize: $pageSize
  ) {
    items {
      ... on OrganizationEvent {
        organizationEventUuid
        organizationUuid
        categoryForEventUuid
        isApprovedToPublish
        publishAt
        expireAt
        wasRejected
        rejectedReason
        rejectedAt
        beginAt
        endAt
        name
        venue
        instructions
        description
        comments
        website
        costPerMember
        costPerNonMember
        costPerStudent
        costPhrase
        registrationDeadline
        maxAttendeeCount
        registrationCount
        isAllDayEvent
        isReservationRequired
        reservationWebsite
        reservationContactFullname
        reservationContactEmail
        reservationContactPhone
        reservationContactFax
        reservationContactStreet
        reservationContactAddress
        reservationContactCity
        reservationContactState
        reservationContactPostalCode
        updatedAt
      }
    }
    pagination {
      page
      pageSize
      pageCount
      rowCount
      maxRows
    }
  }
}
`

const LIST_CATEGORIES_EVENTS = gql`
query categories(
  $whichType: CategoryType!
) {
  categories(
    whichType: $whichType
  ) {
    items {
      ... on CategoryForEvent {
        categoryForEventUuid
        parentCategoryUuid
        name
        slug
        viewOrder
      }
    }
    pagination {
      page
      pageSize
      pageCount
      rowCount
      maxRows
    }
  }
}
`

const LIST_CATEGORIES_ORGANIZATIONS = gql`
query categories(
  $whichType: CategoryType!
) {
  categories(
    whichType: $whichType
  ) {
    items {
      ... on CategoryForOrganization {
        categoryForOrganizationUuid
        parentCategoryUuid
        name
        slug
        viewOrder
      }
    }
    pagination {
      page
      pageSize
      pageCount
      rowCount
      maxRows
    }
  }
}
`

const LIST_GEO_STATES = gql`
query geoStates {
  geoStates {
    items {
      ... on GeoState {
        code
        name
      }
    }
    pagination {
      page
      pageSize
      pageCount
      rowCount
      maxRows
    }
  }
}
`
const LIST_GEO_CITIES_BY_STATE = gql`
query geoCitiesByState {
  geoCitiesByState {
    items {
      ... on GeoCity {
        geoCityUuid
        name
        area {
          geoCountyAreaUuid
          name
          county {
            geoCountyUuid
            name
          }
        }
        divisions {
          geoCityDivisionUuid
          name
          postalCode
          latitude
          longitude
        }
      }
    }
    pagination {
      page
      pageSize
      pageCount
      rowCount
      maxRows
    }
  }
}
`

export {
  FETCH_ORGANIZATION,
  LIST_ORGANIZATION_MEDIA,
  LIST_IMPRESSIONS,
  LIST_INCENTIVES,
  LIST_EVENTS,
  LIST_CATEGORIES_EVENTS,
  LIST_CATEGORIES_ORGANIZATIONS,
  LIST_GEO_STATES,
  LIST_GEO_CITIES_BY_STATE,
}