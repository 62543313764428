import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAlertContext } from 'context/AlertLayoutContext';
import { LIST_CATEGORIES_EVENTS, LIST_CATEGORIES_ORGANIZATIONS } from 'data/queries';
import {
  IDataPagination,
  ICategoryForEvent,
  ICategoryForOrganization,
} from 'data/types';

export default function useEventHook() {
  const { pushErrorMessage } = useAlertContext();
  const [eventCategories, setEventCategories] = useState<ICategoryForEvent[]>();
  const [organizationCategories, setOrganizationCategories] = useState<ICategoryForOrganization[]>();
  const [eventCategorySelectOptions, setEventCategorySelectOptions] = useState<any[]>();
  const [organizationCategorySelectOptions, setOrganizationCategorySelectOptions] = useState<any[]>();
  const [selectedEventCategory, setSelectedEventCategory] = useState<ICategoryForEvent>();
  const [selectedOrganizationCategory, setSelectedOrganizationCategory] = useState<ICategoryForOrganization>();
  const [listEventCategories, listEventCategoryContext] = useLazyQuery(LIST_CATEGORIES_EVENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const categoryData: IDataPagination = data.categories;
      setEventCategories(categoryData?.items);

      const categories: any[] = [];
      categoryData?.items?.forEach((category: ICategoryForEvent) => {
        categories.push(
          <option key={category.categoryForEventUuid} value={category.categoryForEventUuid}>
            {category.name}
          </option>
        )
      });

      setEventCategorySelectOptions(categories);
    },
    onError: error => {
      console.log('listEventCategories::Error: ', error);
      pushErrorMessage('Unable to list available event categories - ' + error.message)
    }
  })

  const [listOrganizationCategories, listOrganizationCategoryContext] = useLazyQuery(LIST_CATEGORIES_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const categoryData: IDataPagination = data.categories;
      setOrganizationCategories(categoryData?.items);
      
      const categories: any[] = [];
      categoryData?.items?.forEach((category: ICategoryForOrganization) => {
        categories.push(
          <option key={category.categoryForOrganizationUuid} value={category.categoryForOrganizationUuid}>
            {category.name}
          </option>
        )
      });
      
      setOrganizationCategorySelectOptions(categories);
    },
    onError: error => {
      console.log('listOrganizationCategories::Error: ', error);
      pushErrorMessage('Unable to list available organization categories - ' + error.message)
    }
  })

  const listCategories = (whichType: string) => {
    if (whichType === 'EVENT') {
      listEventCategories({
        variables: {
          whichType,
        }
      })
    } else if (whichType === 'ORGANIZATION') {
      listOrganizationCategories({
        variables: {
          whichType,
        }
      })
    }
  }

  const categoryNameFromUuid = (categoryUuid: string, whichType: string = 'ORGANIZATION'): string | undefined => {
    if (whichType === 'ORGANIZATION' && organizationCategories) {
      for(let ndx=0;ndx < organizationCategories?.length;ndx++) {
        if (categoryUuid === organizationCategories[ndx].categoryForOrganizationUuid) {
          return organizationCategories[ndx].name;
        }
      }
    } else if(whichType === 'EVENT' && eventCategories) {
      for(let ndx=0;ndx < eventCategories?.length;ndx++) {
        if (categoryUuid === eventCategories[ndx].categoryForEventUuid) {
          return eventCategories[ndx].name;
        }
      }
    }

    return undefined;
  }

  return {
    selectedEventCategory, setSelectedEventCategory,
    selectedOrganizationCategory, setSelectedOrganizationCategory,
    eventCategorySelectOptions,
    organizationCategorySelectOptions,
    categoryNameFromUuid,
    listCategories,
    eventCategories,
    organizationCategories,
    listEventCategoryContext,
    listOrganizationCategoryContext,
  }
}