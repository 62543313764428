import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useAlertContext } from 'context/AlertLayoutContext';
import { LIST_EVENTS } from 'data/queries';
import { PERSIST_EVENT, ARCHIVE_EVENT } from 'data/mutations';
import { DateTime } from 'luxon';
import {
  IDataPagination,
  IOrganizationEvent,
  IPagination,
} from 'data/types';
import { omit } from 'lodash'

export default function useEventHook() {
  const blankEvent: IOrganizationEvent = {
    organizationEventUuid: '',
    organizationUuid: '',
    categoryForEventUuid: '',
    wasRejected: false,
    isApprovedToPublish: true,
    publishAt: DateTime.now().toISO(),
    expireAt: DateTime.now().plus({days: 1}).toISO(),
    beginAt: DateTime.now().toISO(),
    endAt: DateTime.now().plus({days: 1}).toISO(),
    name: '',
    venue: '',
    instructions: '',
    description: '',
    comments: '',
    website: '',
    costPerMember: 0.0,
    costPerNonMember: 0.0,
    costPerStudent: 0.0,
    costPhrase: '',
    registrationDeadline: undefined,
    maxAttendeeCount: 0,
    registrationCount: 0,
    isAllDayEvent: false,
    isReservationRequired: false,
    reservationWebsite: '',
    reservationContactFullname: '',
    reservationContactEmail: '',
    reservationContactPhone: '',
    reservationContactFax: '',
    reservationContactStreet: '',
    reservationContactCity: '',
    reservationContactState: '',
    reservationContactPostalCode: ''
}

  const { pushErrorMessage, pushMessageWithContext, AlertContextType } = useAlertContext();
  const [events, setEvents] = useState<IOrganizationEvent[]>();
  const [eventPagination, setEventPagination] = useState<IPagination>();
  const [selectedEvent, setSelectedEvent] = useState<IOrganizationEvent>(blankEvent);

  const [listEvents, listEventContext] = useLazyQuery(LIST_EVENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const eventData: IDataPagination = data.events;
      setEvents(eventData?.items);
      setEventPagination(eventData?.pagination);
    },
    onError: error => {
      console.log('listEvents::Error: ', error);
      pushErrorMessage('Unable to list available events - ' + error.message)
    }
  })
  const [persistEvent, persistEventContext] = useMutation(PERSIST_EVENT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_EVENTS],
    onCompleted: data => {
      const eventData: IOrganizationEvent = data.persistEvent;
      setSelectedEvent(eventData);
      pushMessageWithContext(AlertContextType.Success, 'Event saved successfully')
    },
    onError: error => {
      console.log('persistEvent::Error: ', error);
      pushErrorMessage('Unable to save events - ' + error.message)
    }
  })
  const [archiveEvent, archiveEventContext] = useMutation(ARCHIVE_EVENT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_EVENTS],
    onCompleted: data => {
      const eventData: IOrganizationEvent = data.persistEvent;
      setSelectedEvent(blankEvent);
      pushMessageWithContext(AlertContextType.Success, 'Event archived successfully')
    },
    onError: error => {
      console.log('archiveEvent::Error: ', error);
      pushErrorMessage('Unable to archive event - ' + error.message)
    }
  })

  const list = (organizationUuid: string, page: number, pageSize: number) => {
    listEvents({
      variables: {
        organizationUuid,
        page,
        pageSize,
      }
    })
  }

  const saveEvent = (param: IOrganizationEvent) => {
    persistEvent({
      variables: {
        param: omit(param, ['__typename']),
      }
    })
  }

  const deleteEvent = (uuid: string) => {
    archiveEvent({
      variables: {
        organizationEventUuid: uuid,
      }
    })
  }

  return {
    blankEvent,
    events,
    eventPagination,
    selectedEvent,
    setSelectedEvent,
    list,
    eventListContext: listEventContext,
    isLoadingEvents: listEventContext.loading,
    saveEvent,
    eventSaveContext: persistEventContext,
    isSavingEvent: persistEventContext.loading,
    deleteEvent,
    eventArchiveContext: archiveEventContext,
    isArchivingEvent: archiveEventContext.loading,
  }
}