import React, { useState, useContext, createContext } from 'react';
import { AlertNoticeAnchor } from 'context/AlertLayoutContext';
import PageLayoutHeader from 'context/PageLayoutContext/PageLayoutHeader';
import PageLayoutFooter from 'context/PageLayoutContext/PageLayoutFooter';
import ThreeDotLoader from 'views/components/loaders';

interface IPLContentProps {
  loading?: boolean | undefined,
  noheader?: boolean | undefined,
  children: any,
}

const PageLayoutContainer = (props: any) => {
  return (
    <div className='pagelayout-container'>
      {props.children}
    </div>
  )
}

const PageLayoutFixedSubNavBar = (props: any) => {
  return (
    <div className='secondary-navigation'>
      <div className={`container${props.pushRight ? ' push-right': ''}`}>
        {props.children}
      </div>
    </div>
  )
}

const PageLayoutContent = (props: IPLContentProps) => {
  const { children, noheader, loading } = props;
  return (
    <React.Fragment>
      <AlertNoticeAnchor />
      <div className={`pagelayout-${noheader ? 'noheader-' : ''}content`}>
        <ThreeDotLoader show={loading || false} />
        <div className='container'>
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}

const PageLayoutContext = createContext({});

interface IPageLayoutType {
  Authenticated: string
  Unauthenticated: string
}

const PageLayoutType: IPageLayoutType = {
  Authenticated: 'bg-primary',
  Unauthenticated: 'bg-registration'
}

const PageLayoutProvider = (props: any) => {
  const [pageLayout, setPageLayout] = useState<string>(PageLayoutType.Authenticated);

  const switchPageLayout = (layout: string) => {
    switch(layout) {
      case PageLayoutType.Authenticated:
        setPageLayout(PageLayoutType.Authenticated);
        break;
      case PageLayoutType.Unauthenticated:
      default:
        setPageLayout(PageLayoutType.Unauthenticated);
    }
  }
  
  return (
    <PageLayoutContext.Provider value={{
      switchPageLayout,
    }}>
      <PageLayoutHeader layout={pageLayout}/>
        {props.children}
      <PageLayoutFooter />
    </PageLayoutContext.Provider>
  )
}

const usePageLayout = (): any => {
  const context = useContext(PageLayoutContext)
  if (context === undefined) {
    throw new Error('usePageLayout must be used within PageLayoutProvider');
  }
  return context;
}

export { 
  PageLayoutContext, 
  PageLayoutProvider, 
  PageLayoutContainer, 
  PageLayoutFixedSubNavBar,
  PageLayoutContent,
  usePageLayout,
  PageLayoutType,
};