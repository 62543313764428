import { gql } from '@apollo/client';

const CREATE_ORGANIZATION = gql`
mutation createOrganization(
  $param: OrganizationInput!,
  $geoCityUuid: String!,
  $contact: ContactInput!
) {
  createOrganization(
    param: $param,
    geoCityUuid: $geoCityUuid,
    contact: $contact
  ) {
    organizationUuid
    primaryCategoryUuid
    primaryCategory {
      name
    }
    secondaryCategoryUuid
    secondaryCategory {
      name
    }
    name
    slug
    description
    phonePrimary
    phoneSecondary
    fax
    email
    website
    street
    address
    city
    provinceOrState
    postalCode
    country
    keywords
    acceptedCreditCards {
      name
      accepted
    }
    hasDelivery
    hasParking
    createdAt
    updatedAt
  }
}
`

const PERSIST_ORGANIZATION = gql`
mutation updateOrganization(
  $param: OrganizationInput!
) {
  updateOrganization(
    param: $param
  ) {
    organizationUuid
    primaryContactUuid
    primaryCategoryUuid
    primaryCategory {
      name
    }
    secondaryCategoryUuid
    secondaryCategory {
      name
    }
    mediaUuid
    legacyOwnerId
    name
    slug
    description
    phonePrimary
    phoneSecondary
    fax
    email
    website
    street
    address
    city
    provinceOrState
    postalCode
    country
    keywords
    acceptedCreditCards {
      name
      accepted
    }
    hoursOfOperation {
      dayOfWeek
      hours {
        isOpen
        openAt
        closeAt
      }
    }
    hasDelivery
    hasParking
    createdAt
    updatedAt
  }
}
`

const PERSIST_EVENT = gql`
mutation persistEvent($param: OrganizationEventInput!) {
  persistEvent(param: $param) {
    organizationEventUuid
    organizationUuid
    categoryForEventUuid
    isApprovedToPublish
    publishAt
    expireAt
    wasRejected
    rejectedReason
    rejectedAt
    beginAt
    endAt
    name
    venue
    instructions
    description
    comments
    website
    costPerMember
    costPerNonMember
    costPerStudent
    costPhrase
    registrationDeadline
    maxAttendeeCount
    registrationCount
    isAllDayEvent
    isReservationRequired
    reservationWebsite
    reservationContactFullname
    reservationContactEmail
    reservationContactPhone
    reservationContactFax
    reservationContactStreet
    reservationContactAddress
    reservationContactCity
    reservationContactState
    reservationContactPostalCode
    updatedAt
  }
}
`

const ARCHIVE_EVENT = gql`
mutation archiveEvent($organizationEventUuid: ID!) {
  archiveEvent(organizationEventUuid: $organizationEventUuid) {
    success
  }
}
`

const PERSIST_INCENTIVE = gql`
mutation persistIncentive($param: OrganizationIncentiveInput!) {
  persistIncentive(param: $param) {
    organizationIncentiveUuid
    organizationUuid
    mediaUuid
    name
    description
    publishAt
    validAt
    expireAt
    viewOrder
    updatedAt
  }
}
`

const ARCHIVE_INCENTIVE = gql`
mutation archiveIncentive($organizationIncentiveUuid: ID!) {
  archiveIncentive(organizationIncentiveUuid: $organizationIncentiveUuid) {
    success
  }
}
`

const RESET_ACCOUNT_CREDENTIALS = gql`
mutation changePassword(
  $identityUuid: ID!,
  $newUsername: String!,
  $newPassword: String!
) {
  changeCredentials(
    identityUuid: $identityUuid,
    newUsername: $newUsername,
    newPassword: $newPassword
  ) {
    success
  }
}
`

const MEDIA_REQUEST_UPLOAD = gql`
mutation requestMediaUpload(
  $organizationUuid: ID!,
  $filename: String!
  $segment: String!
  $mimeType: String!
  $contentLength: Int!
) {
  requestMediaUpload(
    organizationUuid: $organizationUuid,
    filename: $filename,
    segment: $segment,
    mimeType: $mimeType,
    contentLength: $contentLength
  ) {
    organizationUuid
    uploadUrl
    expiresAt
  }
}
`

const MEDIA_CONFIRM_UPLOAD = gql`
mutation confirmMediaUpload(
  $organizationUuid: ID!,
  $metadata: OctetMetadata!
) {
  confirmMediaUpload(
    organizationUuid: $organizationUuid,
    metadata: $metadata
  ) {
    mediaUuid
    serverFilename
    serverUri
    originalFilename
    mimeType
    slug
    caption
    attributes
  }
}
`

const MEDIA_ARCHIVE = gql`
mutation archiveMedia(
  $organizationMediaUuid: ID!
) {
  archiveMedia(
    organizationMediaUuid: $organizationMediaUuid
  ) {
    success
  }
}
`

const MEDIA_CAPTION_UPDATE = gql`
mutation updateMediaCaption(
  $organizationMediaUuid: ID!,
  $mediaUuid: String!,
  $caption: String!
) {
  updateMediaCaption(
    organizationMediaUuid: $organizationMediaUuid,
    mediaUuid: $mediaUuid,
    caption: $caption
  ) {
    success
  }
}
`

export {
  CREATE_ORGANIZATION,
  PERSIST_EVENT,
  ARCHIVE_EVENT,
  PERSIST_INCENTIVE,
  ARCHIVE_INCENTIVE,
  PERSIST_ORGANIZATION,
  RESET_ACCOUNT_CREDENTIALS,
  MEDIA_REQUEST_UPLOAD,
  MEDIA_CONFIRM_UPLOAD,
  MEDIA_ARCHIVE,
  MEDIA_CAPTION_UPDATE,
}